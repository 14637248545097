export default {
    baseURL: 'https://api.e-tchite.bj',
    // baseURL: 'http://localhost:8000',
    baseURLAccountant: 'https://api.etchite.dev.41devs.co',
    login: '/api/v1/admin/login',
    allCommerce: '/api/v1/entreprise/admin',
    allCommerceParginate: '/api/v1/entreprise/paginate',
    commerceDetail: '/api/v1/admin/commerce/detail',
    bilanStat: '/api/v1/admin/commerce/bilan',
    allClient: '/api/v1/admin/commerce/searchData',
    allSfd: '/api/v1/sfd',
    createSfd: '/api/v1/sfd',
    createSfdAdmin: '/api/v1/admin/createAdminSfd',
    allAdmin: '/api/v1/admin',
    createCommercialSfd: '/api/v1/admin/createCommercialSfd',
    allEnroleForCommercial: '/api/v1/sfdParticipation/commercialEnrol',
    allCoupons: '/api/v1/commercial',
    generateCoupon: '/api/v1/commercial/generate',
    createAdmin: '/api/v1/admin/createAdmin',
    allStat: '/api/v1/admin/stats/commerce',
    abonnementStat: '/api/v1/admin/stats/abonnement',
    desactiveCommercial: '/api/v1/admin/commercialActivation',
    statBySfd: '/api/v1/admin/stats/statForOneSfd',
    changePass: '/api/v1/auth/changeActualPassword',
    allDemande: '/api/v1/entreprise/dgi/allDemande',
    create_chartered_accountant: '/auth/create-chartered-accountant',
    get_all_accountant: '/auth/all-accountant',
    upload_file: '/firebase-storage/upload',
    get_all_affected: '/affectation/get-all',
    create_affectation: '/affectation/create-in-previous-api'
}
